<template>
  <div>
    <div class="pay-s" :style="payColor">
      <div class="pay-box">
        <img
          src="../../../assets/image/icon_wechat.png"
          alt="支付"
          class="ali-img"
        />
        <div class="pay-is-s">支付成功</div>
      </div>
      <div class="pay-amount"><span>￥</span>{{ '100.0' }}</div>
      <!-- <div class="pay-go">
        <button class="pay-btn" @click="payOver">完成</button>
      </div> -->
      <!-- <div class="search-order">
        <span>查询订单结果</span>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isPay: 'ali-pay',
    };
  },

  computed: {
    payColor() {
      return { '--ispay': '#15ba11' };
    },
  },
  methods: {
    payOver() {},
  },
};
</script>
<style lang="less" scoped>
.pay-s {
  background-color: #fff;
  position: relative;
  height: 100vh;
  overflow: hidden;
  .pay-box {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    .ali-img {
      width: 88px;
      height: 88px;
    }
    .pay-is-s {
      color: var(--ispay);
    }
  }
  .pay-amount {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 36px;
    font-weight: 500;
  }
  .pay-go {
    .pay-btn {
      margin-top: 50px;
      width: 150px;
      height: 40px;
      border-radius: 23px;
      border: 1px solid var(--ispay);
      background-color: var(--ispay);
      color: #fff;
    }
  }
  .search-order {
    margin-top: 140px;
    color: #bebebe;
  }
}
</style>
